<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Teknik Servisler</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="TeknikServis()" />
        </div>
        <DataTable v-if="DataTable_render" :columns="columns" :data="ServislerListesi"
          v-on:set-child-data="getDataChild" />
      </template>
    </Card>

    <Dialog v-model:visible="TeknikServisModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Yeni Teknik Servis</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanServisAd">Servis Adı</span>
          <InputText id="txtServisAd" type="text" v-model="v$.txtServisAd.$model" aria-labelledby="spanServisAd"
            :class="{ 'p-invalid': v$.txtServisAd.$invalid && submitted }" />
          <small v-if="(v$.txtServisAd.$invalid && submitted) ||
            v$.txtServisAd.$pending.$response
            " class="p-error">{{
            v$.txtServisAd.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanTelefon">Telefon Numarası</span>
          <InputMask id="txtTelno" mask="(999) 999-9999" v-model="txtTelno" placeholder="(___) ___-____"
            :class="{ 'p-invalid': v$.txtTelno.$invalid && submitted }" />
          <small v-if="(v$.txtTelno.$invalid && submitted) ||
            v$.txtTelno.$pending.$response
            " class="p-error">{{ v$.txtTelno.required.$message.replace("Value", "Name") }}</small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanMailAdres">Mail Adres</span>
          <InputText id="txtMailAdres" type="text" v-model="v$.txtMailAdres.$model" aria-labelledby="spanMailAdres"
            :class="{ 'p-invalid': v$.txtMailAdres.$invalid && submitted }" />
          <small v-if="(v$.txtMailAdres.$invalid && submitted) ||
            v$.txtMailAdres.$pending.$response
            " class="p-error">{{
            v$.txtMailAdres.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-6"></div>
        <div class="p-field p-col-12">
          <span for="spanAdress">Adres</span>
          <Textarea id="txtServisAdress" v-model="v$.txtServisAdress.$model" :autoResize="false" rows="2"
            aria-labelledby="spanAdress" :class="{ 'p-invalid': v$.txtServisAdress.$invalid && submitted }" />
          <small v-if="(v$.txtServisAdress.$invalid && submitted) ||
            v$.txtServisAdress.$pending.$response
            " class="p-error">{{
            v$.txtServisAdress.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanSehir">Şehir</span>
          <Dropdown filter id="drpSehir" v-model="v$.SelectedSehir.$model" :options="Sehir" optionLabel="SehirAdi"
            optionValue="SehirKod" placeholder="Seçiniz..."
            :class="{ 'p-invalid': v$.SelectedSehir.$invalid && submitted }" @change="CityChange" />
          <small v-if="(v$.SelectedSehir.$invalid && submitted) ||
            v$.SelectedSehir.$pending.$response
            " class="p-error">{{
            v$.SelectedSehir.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanIlçe">İlçe</span>
          <Dropdown filter id="drpIlce" v-model="v$.SelectedIlce.$model" :disabled="ilceDisabled" :options="Ilce"
            optionLabel="IlceAdi" optionValue="IlceKod" placeholder="Seçiniz..." :class="{
            'p-invalid': v$.SelectedIlce.$invalid && submitted,
          }" />
          <small v-if="(v$.SelectedIlce.$invalid && submitted) ||
            v$.SelectedIlce.$pending.$response
            " class="p-error">{{
            v$.SelectedIlce.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanKullanıcı">Sorumlu Kullanıcı</span>
          <Dropdown filter id="drpKullanıcı" v-model="v$.SelectedKullanici.$model" :options="KullanicilarListesi"
            optionLabel="Ad" optionValue="Kullanici_rowid" placeholder="Seçiniz..." :class="{
            'p-invalid': v$.SelectedKullanici.$invalid && submitted,
          }" />
          <small v-if="(v$.SelectedKullanici.$invalid && submitted) ||
            v$.SelectedKullanici.$pending.$response
            " class="p-error">{{
            v$.SelectedKullanici.required.$message.replace("Value", "Name")
          }}</small>
        </div>
        <div class="p-field p-col-4">
          <span for="spanCihazTur">Cihaz Türü</span>
          <Dropdown filter id="drpCihazTur" v-model="v$.SelectedCihazTur.$model" :options="CihazTurListesi"
            optionLabel="UrunAdi" optionValue="UrunKey" placeholder="Seçiniz..." :class="{
            'p-invalid': v$.SelectedCihazTur.$invalid && submitted,
          }" />
          <small v-if="(v$.SelectedCihazTur.$invalid && submitted) ||
            v$.SelectedCihazTur.$pending.$response
            " class="p-error">{{
            v$.SelectedCihazTur.required.$message.replace("Value", "Name")
          }}</small>
        </div>

        <div class="p-field p-col-4">
          <span for="spanHizmetBedeli">Hizmet Bedeli( % )</span>
          <span class="p-input-icon-right">
            <i class="pi pi-percentage" />
            <InputNumber id="txtHizmetBedeli" type="text" v-model="v$.txtHizmetBedeli.$model" aria-labelledby="spanHizmetBedeli"
            :class="{ 'p-invalid': v$.txtHizmetBedeli.$invalid && submitted }" />
          <small v-if="(v$.txtHizmetBedeli.$invalid && submitted) ||
            v$.txtHizmetBedeli.$pending.$response
            " class="p-error">{{
            v$.txtHizmetBedeli.required.$message.replace("Value", "Name")
          }}</small>
          </span>
        </div>

        <div class="p-field p-col-4">
          <span for="spanParaBirim">Para Birim</span>
          <Dropdown filter id="drpParaBirim" v-model="v$.SelectedParaBirim.$model" :options="ParaBirimListesi"
            optionLabel="KodAd" optionValue="ParaBirim_rowid" placeholder="Seçiniz..." :class="{
            'p-invalid': v$.SelectedParaBirim.$invalid && submitted,
          }" />
          <small v-if="(v$.SelectedParaBirim.$invalid && submitted) ||
            v$.SelectedParaBirim.$pending.$response
            " class="p-error">{{
            v$.SelectedParaBirim.required.$message.replace("Value", "Name")
          }}</small>
        </div>

        <div class="p-field p-col-8">
          <FileUpload v-if="ResimUploadShow" name="demo[]" :customUpload="true" chooseLabel="Seçin" uploadLabel="Yükle"
            cancelLabel="İptal" :multiple="true" accept="image/png, image/jpeg" :maxFileSize="1000000"
            buttonClass="p-button-sm" :fileLimit="4" @uploader="_ResimUpload" @progress="Progress">
            <template #empty>
              <p>Resimlerinizi buraya sürükleyebilirsiniz.</p>
            </template>
          </FileUpload>
        </div>

      </div>
      <template #footer>
        <Button label="Oluştur" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="TeknikServis()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      allData: null,
      TeknikServisModal: false,
      TeknikServisPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Teknik Servis, False = Edit Teknik Servis
      ServislerListesi: [],
      SehirIlce: [],
      Sehir: [],
      Ilce: [],
      KullanicilarListesi: [],
      CihazTurListesi: [],
      ParaBirimListesi:[],
      columns: [
        { field: "ServisAdi", header: "Servis Adı" },
        { field: "Adres", header: "Adres" },
        { field: "SehirAdi", header: "Sehir" },
        { field: "IlceAdi", header: "İlçe" },
        { field: "KullaniciAd", header: "Sorumlu" },
      ],
      SelectedSehir: null,
      SelectedIlce: null,
      SelectedKullanici: null,
      txtServisAd: null,
      txtTelno: null,
      txtServisAdress: null,
      txtMailAdres: null,
      SelectedCihazTur: null,
      submitted: false,
      ilceDisabled: true,
      TeknikServis_rowid: null,
      txtHizmetBedeli: 0,
      SelectedParaBirim: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.TeknikServisPopUpDurumu = false;
      this.TeknikServisModal = !this.TeknikServisModal;
      this.TeknikServis_rowid = getdata_child.data.TeknikServis_rowid;
      let data = {
        TeknikServis_rowid: getdata_child.data.TeknikServis_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetServisInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.txtServisAd.$model = res.data[0].Ad;
          this.v$.txtTelno.$model = res.data[0].TelefonNo;
          this.v$.txtServisAdress.$model = res.data[0].Adres;
          this.v$.SelectedSehir.$model = res.data[0].pSehir;
          this.v$.SelectedIlce.$model = res.data[0].pIlce;
          this.v$.SelectedKullanici.$model = res.data[0].pKullanici;
          this.v$.SelectedCihazTur.$model = res.data[0].pUrunTur;
          this.v$.txtHizmetBedeli.$model = res.data[0].HizmetBedeli;
          this.v$.SelectedParaBirim.$model = res.data[0].pParaBirim;
          this.v$.txtMailAdres.$model = res.data[0].MailAdres;
          this.ilceDisabled = false;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          TeknikServis_rowid: this.TeknikServis_rowid,
          pSehir: this.SelectedSehir,
          pIlce: this.SelectedIlce,
          pKullanici: this.SelectedKullanici,
          sAdi: this.txtServisAd,
          sTelefonNo: this.txtTelno,
          sAdres: this.txtServisAdress,
          pUrunTur: this.SelectedCihazTur,
          HizmetBedeli : this.txtHizmetBedeli,
          pParaBirim : this.SelectedParaBirim,
          MailAdres : this.txtMailAdres,
        };
        if (this.TeknikServisPopUpDurumu) {
          // Create
          GlobalServis.GlobalServis("POST", "SetServis", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Teknik servis oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this.resetForm();
              this.TeknikServisModal = !this.TeknikServisModal;
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          // Update
          GlobalServis.GlobalServis("POST", "UpdateServis", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Teknik servis düzenlenmiştir",
                  life: 3000,
                });
                this.Refresh();
                this.resetForm();
                this.TeknikServisModal = !this.TeknikServisModal;
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    TeknikServis() {
      this.TeknikServisPopUpDurumu = true;
      this.TeknikServisModal = !this.TeknikServisModal;
      if (!this.TeknikServisModal) {
        this.resetForm();
      }
    },
    CityChange(e) {
      this.Ilce = this.allData.Ilce.filter(function (i) {
        if (e.value == 0) {
          return i.pSehir == e.value.substring(1);
        } else {
          return i.pSehir == e.value;
        }
      });
      this.ilceDisabled = false;
    },
    resetForm() {
      this.TeknikServisPopUpDurumu = null;
      // this.v$.$reset;
      this.submitted = false;
      this.SelectedSehir = "01";
      this.SelectedIlce = null;
      this.SelectedKullanici = null;
      this.txtServisAd = null;
      this.txtTelno = null;
      this.txtServisAdress = null;
      this.SelectedCihazTur = null;
      this.ilceDisabled = true;
      this.txtHizmetBedeli = true;
      this.SelectedParaBirim = null;
      this.txtMailAdres = null;
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    _GetServisTablosu(){
      GlobalServis.GlobalServis("GET", "GetServisTablosu", "").then((res) => {
        if (res.status == 200) {
          this.ServislerListesi = res.data;
          console.log(res.data);
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _GetServisOlusturData(){
      GlobalServis.GlobalServis("GET", "GetServisOlusturData", "").then(
        (res) => {
          if (res.status == 200) {
            this.allData = res.data;
            this.Sehir = res.data.Sehir;
            this.Ilce = res.data.Ilce;
            this.KullanicilarListesi = res.data.KullaniciListesi;
            this.CihazTurListesi = res.data.CihazTur;
            this.ParaBirimListesi = res.data.ParaBirim;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    InitServis() {
     this._GetServisTablosu();
     this._GetServisOlusturData();
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      SelectedSehir: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedIlce: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedKullanici: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtServisAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtTelno: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtServisAdress: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedCihazTur: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtHizmetBedeli: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedParaBirim: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtMailAdres: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
